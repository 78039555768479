import { trapFocus } from './global.js';
class BackBtn extends HTMLElement {
  constructor() {
    super();
    this.container = this.closest('.overlay-section.overlay-menu');
    this.mainDetailsToggle = this.closest('details');
    this.summary = this.mainDetailsToggle.querySelector('summary');
    this.init();
  }

  init() {
    this.addEventListener('click', this.onClose.bind(this), false);
    this.addEventListener(
      'keypress',
      function (event) {
        if (event.key === 'Enter') {
          this.onClose.bind(this)(event);
        }
      }.bind(this),
      false
    );
  }

  onClose(e) {
    e.preventDefault();
    e.stopPropagation();
    trapFocus(this.container, this.summary);
    const sub_menu = this.closest('.menu-opening');
    const parent_menu = this.closest('ul.parent-menu');
    if (sub_menu) {
      sub_menu.classList.remove('menu-opening');
    }
    if (parent_menu) {
      parent_menu.classList.remove('submenu-open');
    }
    setTimeout(() => {
      this.mainDetailsToggle.removeAttribute('open');
    }, 500);
    this.mainDetailsToggle.querySelector('summary').setAttribute('aria-expanded', false);
  }
}

export default BackBtn;
